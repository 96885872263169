import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getTestToken } from "../../store/actions/blockchainActions";
import DropdownSelect from "./FormComponents/DropdownSelect";
import Loader from "./Loader";

const GetTestTokenForm = React.memo((props) => {
  const [canProceed, setCanProceed] = useState(false);
  const [selected, setSelected] = useState(props.gatewayWallets ? props.gatewayWallets[0] : null);
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(null);

  const handleGenerateTestToken = useCallback(async () => {
    if (selected && canProceed) {
      setIsTransactionInProgress(true);
      await props.getTestToken(selected);
      props.handleReturn();
    }
  }, [selected, canProceed, props]);
  useEffect(() => {
    if (selected) setCanProceed(true);
  }, [selected]);
  const handleCurrencyChange = useCallback((option) => {
    setSelected(option);
  }, []);

  if (isTransactionInProgress) {
    return <Loader />;
  } else
    return (
      <>
        <h2 className="modal-title">دریافت توکن تست</h2>
        <h3 className="modal-content">
          برای دریافت، یکی از توکن ها را انتخاب کنید. توکن دریافت شده صرفا برای تست کارایی درگاه و
          فاقد ارزش است.
        </h3>
        <DropdownSelect
          name="currencyType"
          label="نوع ارز"
          id="currency-type"
          parentClass="select-test-currency"
          selectClass="select"
          onChangeAction={handleCurrencyChange}
          options={props.gatewayWallets.filter((el) => el.isToken)}
        />
        <div className="button-wrapper single-line">
          <button
            className={`button return${!canProceed ? " disable" : ""}`}
            onClick={() => {
              handleGenerateTestToken();
            }}
            disabled={!canProceed}
          >
            دریافت
          </button>
          <button
            className={`button return`}
            onClick={() => {
              props.handleReturn();
            }}
          >
            بازگشت
          </button>
        </div>
      </>
    );
});

GetTestTokenForm.propTypes = {
  handleReturn: PropTypes.func,
};

const mapStateToProps = (state) => ({
  web3Utils: state.blockchainData.blockchainData.web3.utils,
  account: state.blockchainData.blockchainData.account,
  wallet: state.blockchainData.blockchainData.wallet,
  gatewayWallets: state.blockchainData.blockchainData.gatewayWallets,
});

const mapDispatchToProps = { getTestToken };

export default connect(mapStateToProps, mapDispatchToProps)(GetTestTokenForm);

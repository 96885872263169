import React, { Component } from "react";
import { connect } from "react-redux";
// Redux actions:
import {
  logout,
  setWalletList,
  loadWeb3WalletConnect,
  loadWeb3Metamask,
  loadBlockchainData,
  loadGatewayWalletData,
  resetTransactionResults,
  getMissingReciepts,
} from "../store/actions/blockchainActions";
// Components
import GatewayOwnerPage from "./partials/GatewayOwnerPage";
import GatewayUserPage from "./partials/GatewayUserPage";
import Landing from "./partials/Landing";
import Loading from "./Loading";
import Navbar from "./partials/Navbar";
import TransactionSuccess from "./partials/TransactionSuccess";
import LoginOptionCard from "./partials/FormComponents/LoginOptionCard";
import ModalOverlay from "./partials/ModalOverlay";
// import GetTestTokenForm from "./partials/GetTestTokenForm";
// assets and css:
import walletConnect from "../assets/logo/walletConnecct.svg";
import metaMask from "../assets/logo/metamask-fox.svg";
import "../assets/fonts/icons/style.css";
import "./App.css";

class App extends Component {
  // gatewayWallets is a lsit of hard-coded wallet information, can be replaced dynamically later.
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isBlockchainDataLoaded: false,
      browserSupportsMetamask: false,
    };
    // set eventListeners for etherium connection(will throw error if no blockchain extensions be found. )

    if (window.ethereum) {
      try {
        window.ethereum.on("accountsChanged", (accounts) => {
          this.resetApp();
          this.reloadWallets();
          window.location.reload();
        });

        window.ethereum.on("chainChanged", (chainId) => {
          //console.log(chainId);
          this.reloadWallets();
          window.location.reload();
        });
        window.ethereum.on("confirmation", console.log);
        window.ethereum.on("message", (message) => {
          // console.log(message);
        });
        window.ethereum.on("error", (error) => {
          console.log(error);
        });

        // unsubscribes the subscription
        // subscription.unsubscribe(function (error, success) {
        //   if (success) console.log("Successfully unsubscribed!");
        // });

        this.state.browserSupportsMetamask = true;
      } catch (error) {
        // throw error for now, pass to error handling later down the road.
        window.alert(error);
        this.resetApp();
      }
    }
  }
  gatewayWallets = [
    {
      label: "تتر",
      name: "tether",
      weiConversion: "ether",
      tokenAddress: "0xD8bbbDfEFeD7b30a608d24765600669dde1d0Cf2",
      exchangeRate: 4208.397,
      isToken: true,
    },
    {
      label: "اتر",
      name: "ether",
      weiConversion: "ether",
      tokenAddress: "0x0000000000000000000000000000000000000000",
      exchangeRate: 7334269.95,
      isToken: false,
    },
    {
      label: "دای",
      name: "dai",
      tokenAddress: "0x4c0C2FD204b30DD7fB7AC04A7dCC05AD66911374",
      exchangeRate: 870363.5,
      isToken: true,
      weiConversion: "ether",
    },
    {
      label: "رپد بیت کوین",
      name: "wbtc",
      tokenAddress: "0x92D55a05794D08b1C191838269cf1a9297677f83",
      exchangeRate: 870363.5,
      isToken: true,
      weiConversion: "ether",
    },
  ].filter((x) => (window.payJson ? x.name === window.payJson.Currency : true));
  componentDidMount() {
    this.setState({ loading: true, isBlockchainDataLoaded: false });
  }
  resetApp = () => {
    this.setState({ loading: true, isBlockchainDataLoaded: false });
    this.props.resetTransactionResults();
    this.props.logout();
  };
  reloadWallets = async () => {
    this.props.resetTransactionResults();
    await this.props.loadGatewayWalletData(
      this.props.blockchainData.web3,
      this.gatewayWallets,
      this.props.blockchainData,
    );
    this.setState({ loading: false, isBlockchainDataLoaded: true });
  };
  returnToShopSuccess = (transactionHash, reloadWallet = false) => {
    const { owner, account } = this.props.blockchainData;
    if (owner === account || reloadWallet === true) this.reloadWallets();
    else if (window.payJson)
      window.location.href = window.payJson.CallbackUrl + "?status=1&txHash=" + transactionHash;
    else this.reloadWallets();
  };
  loadWeb3Metamask = async () => {
    try {
      this.props.resetTransactionResults();
      await this.props.loadWeb3Metamask();
      const web3Object = this.props.blockchainData.web3;
      this.setState({ loading: true });
      const walletData = await this.props.loadBlockchainData(web3Object);
      await this.props.loadGatewayWalletData(web3Object, this.gatewayWallets, walletData);
      await this.props.getMissingReciepts();
    } catch (error) {
      console.log(error);
      this.resetApp();
      return;
    }
    this.setState({ loading: false, isBlockchainDataLoaded: true });
  };

  loadWeb3WalletConnect = async () => {
    try {
      this.props.resetTransactionResults();
      await this.props.loadWeb3WalletConnect();
      const web3Object = this.props.blockchainData.web3;
      this.setState({ loading: true });
      const walletData = await this.props.loadBlockchainData(web3Object);
      await this.props.loadGatewayWalletData(web3Object, this.gatewayWallets, walletData);
    } catch (error) {
      console.log(error);
      this.resetApp();
      return;
    }
    this.setState({ loading: false, isBlockchainDataLoaded: true });
  };
  handleLogin = () => {
    this.setState({ loading: true });
  };
  render() {
    let content;
    const { owner, account, blockpay, wallet: walletAddress } = this.props.blockchainData;
    const { loading, isBlockchainDataLoaded } = this.state;
    if (this.props.results) {
      content = (
        <TransactionSuccess
          resetHandler={this.returnToShopSuccess}
          results={this.props.results}
          isOwner={owner === account}
        />
      );
    } else if (!isBlockchainDataLoaded && !loading) {
      content = <Landing gatewayWallets={this.gatewayWallets} handleLogin={this.handleLogin} />;
    } else if (isBlockchainDataLoaded && owner === account) {
      content = <GatewayUserPage />;
      content = <GatewayOwnerPage />;
    } else if (isBlockchainDataLoaded && owner !== account) {
      content = <GatewayUserPage />;
    } else if (isBlockchainDataLoaded && loading) {
      content = <Loading />;
    }
    if (!this.state.isBlockchainDataLoaded && loading) {
      if (this.state.browserSupportsMetamask) {
        return (
          <>
            <ModalOverlay className="login" title="جهت استفاده از درگاه به کیف پول خود متصل شوید">
              <>
                <LoginOptionCard
                  icon={metaMask}
                  title="Metamask"
                  content="اتصال به کیف پول با افزونه متامسک"
                  action={this.loadWeb3Metamask}
                />
                <LoginOptionCard
                  icon={walletConnect}
                  title="Wallet Connect"
                  content="اتصال به کیف پول با والت کانکت"
                  action={this.loadWeb3WalletConnect}
                />
              </>
            </ModalOverlay>
          </>
        );
      }
      return (
        <ModalOverlay className="login" title="جهت استفاده از درگاه به کیف پول خود متصل شوید">
          <>
            <LoginOptionCard
              icon={walletConnect}
              title="Wallet Connect"
              content="اتصال به کیف پول با والت کانکت"
              action={this.loadWeb3WalletConnect}
            />
          </>
        </ModalOverlay>
      );
    } else {
      return (
        <>
          <Navbar
            isOwner={owner === account}
            walletAddress={walletAddress}
            gatewayAddress={blockpay?._address}
            userWalletAddress={account}
            isLoggedIn={isBlockchainDataLoaded}
            logout={this.resetApp}
            login={this.handleLogin}
            reloadWallets={this.reloadWallets}
          />
          <div className="main-content-style">
            {content}
            {this.state.isLoading && <Loading />}
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ...state.blockchainData,
  web3Object: state.blockchainData?.blockchainData?.web3 || null,
  results: state.results.results,
});

const mapDispatchToProps = {
  logout,
  setWalletList,
  loadWeb3WalletConnect,
  loadWeb3Metamask,
  loadBlockchainData,
  loadGatewayWalletData,
  resetTransactionResults,
  getMissingReciepts,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
